<template>
  <div class="overflow-x-auto border px-4 rounded-lg max-h-96">
    <FeedbackModal
      v-if="isFeedbackModalOpen"
      v-model="isFeedbackModalOpen"
      :conversationCallOrCallInvitationId="selectedConversation.id"
      :prospectName="selectedConversation.prospectName"
      :ambassadorName="userAccount.first + ' ' + userAccount.last"
      :source="selectedConversation.source" />
    <ChatlogModal
      v-if="isChatlogModalOpen"
      :conversation="selectedConversation"
      :user="userAccount"
      @close="isChatlogModalOpen = false" />
    <common-popup
      v-if="closeConversationPopupOpened"
      v-bind="closeConversationPopupProps"
      @first-button="closeConversationPopupOpened = false"
      @second-button="closeConversation"
      @close="closeConversationPopupOpened = false"
      height="h-max" />

    <table class="w-full">
      <thead class="text-grey-500 text-left text-xs select-none">
        <tr class="h-16">
          <th class="z-10 w-1/6 sticky top-0 bg-white pl-4">
            <div
              class="flex items-center cursor-pointer"
              @click="sortByColumn('prospectName')">
              <div class="flex-none">Prospect</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'prospectName'" />
              </div>
            </div>
          </th>
          <th class="z-10 w-1/6 sticky top-0 bg-white">
            <div
              class="flex items-center cursor-pointer"
              @click="sortByColumn('status', 'boolean')">
              <div class="flex-none">Status</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'status'" />
              </div>
            </div>
          </th>
          <th class="z-10 w-1/6 sticky top-0 bg-white">
            <div
              class="flex items-center cursor-pointer mr-14"
              @click="sortByColumn('initialResponse', 'number')"
              content="Ambassador response time from when the initial prospect message or admin request was received."
              v-tippy="{ placement: 'bottom', arrow: true }">
              <div class="flex-none">Initial Response</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'initialResponse'" />
              </div>
            </div>
          </th>
          <th class="z-10 w-1/6 sticky top-0 bg-white">
            <div
              class="flex items-center cursor-pointer mr-14"
              @click="sortByColumn('lastMessage', 'number')">
              <div class="flex-none">Last Message</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'lastMessage'" />
              </div>
            </div>
          </th>
          <th class="z-10 w-1/6 sticky top-0 bg-white">
            <div
              class="flex items-center cursor-pointer mr-14"
              @click="sortByColumn('messagesSent', 'number')">
              <div class="flex-none">Messages Sent</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'messagesSent'" />
              </div>
            </div>
          </th>
          <th class="z-10 w-1/6 sticky top-0 bg-white">
            <div
              class="flex items-center cursor-pointer mr-14"
              @click="sortByColumn('feedbackAvailable', 'boolean')">
              <div class="flex-none">Feedback</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'feedbackAvailable'" />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="text-sm">
        <tr
          class="h-14"
          v-for="(conversation, index) of conversations"
          v-bind:key="`conversation-row-${index}`">
          <td class="pr-10 pl-4">
            <div class="prospect-name-icon">
              {{ conversation.prospectName }}
              <v-icon
                v-if="conversation.blocked"
                small
                v-tippy="{ placement: 'right', arrow: true }"
                :content="$lang.ambassadorPage_UserBlocked"
                class="red-icon">
                $account-cancel
              </v-icon>
            </div>
          </td>
          <td class="pr-10">
            <div
              v-if="conversation.status"
              @click="
                toggleCloseConversationPopup(conversation.id, conversation.type)
              ">
              <div class="group">
                <p class="status active">{{ $lang.status_Active }}</p>
                <p class="status closed close">
                  {{ $lang.conversationTable_Close }}
                </p>
              </div>
            </div>
            <div v-else class="status closed">{{ $lang.status_Closed }}</div>
          </td>
          <td class="pr-10">
            {{ calculateDaysDifference(conversation.initialResponse) }}
          </td>
          <td class="pr-10">
            <div v-if="conversation.type === 'conversation'">
              {{ calculateDaysDifference(conversation.lastMessage) }}
            </div>
            <div v-else-if="conversation.type === 'call'">--</div>
          </td>
          <td class="pr-10">
            <div v-if="conversation.type === 'conversation'">
              <div
                class="flex items-center text-blue hover:text-blue-500 cursor-pointer"
                @click="
                  isChatlogModalOpen = true;
                  selectedConversation = conversation;
                ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                </svg>
                {{ conversation.messagesSent }}
              </div>
            </div>

            <div v-else-if="conversation.type === 'call'">
              <div
                class="flex items-center"
                @click="
                  isChatlogModalOpen = true;
                  selectedConversation = conversation;
                ">
                <img
                  :src="require('@/assets/call-icon.svg')"
                  class="h-4 w-4 mr-2" />

                Phone
              </div>
            </div>
          </td>
          <td>
            <div
              v-if="conversation.feedback"
              class="flex items-center text-blue hover:text-blue-500 cursor-pointer"
              @click="
                isFeedbackModalOpen = true;
                selectedConversation = conversation;
              ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
              Feedback
            </div>
            <div
              v-else
              class="flex items-center text-grey select-none"
              content="No feedback has been provided for this conversation."
              v-tippy="{ placement: 'left', arrow: true }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
              </svg>
              Feedback
            </div>
          </td>
        </tr>

        <!-- Skeleton Loading -->
        <tr
          v-for="(n, index) in 6"
          class="h-14 animate-pulse"
          :class="{ hidden: conversations.length || noConversationsToDisplay }"
          v-bind:key="`skeleton-row-${index}`">
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
        </tr>

        <tr
          class="h-14 relative"
          :class="{ hidden: !noConversationsToDisplay }">
          <td>
            <div class="absolute top-0 left-4">
              There are no conversations to display for this user.
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import sortService from "@/services/sort.service";
  const FeedbackModal = () =>
    import("@/components/common/tailwind/modals/FeedbackModal.vue");
  const ChatlogModal = () =>
    import("@/components/dashboard/profile/profileComponents/ChatlogModal");

  export default {
    name: "ConversationsTable",
    components: {
      FeedbackModal,
      ChatlogModal,
    },
    props: {
      rows: {
        type: Array,
        required: true,
      },
      userAccount: {
        type: Object,
        required: true,
      },
      branding: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        conversations: [],
        displayedConversations: [],
        activeSort: "",
        sortDirection: {},
        noConversationsToDisplay: false,
        closeConversationPopupOpened: false,
        conversationToClose: null,
        closeConversationPopupProps: {
          headerText: "Close conversation?",
          bodyText:
            "This action cannot be reversed by an administrator. However, the conversation can be reopened if either conversation participant sends a new message.",
          firstButton: {
            variant: "info",
            text: "Cancel",
          },
          secondButton: {
            variant: "danger",
            text: "Close",
          },
        },
        isFeedbackModalOpen: false,
        isChatlogModalOpen: false,
        selectedConversation: null,
      };
    },
    watch: {
      rows(updatedConversations) {
        this.noConversationsToDisplay = !updatedConversations.length;
        this.conversations = updatedConversations;
      },
    },
    methods: {
      sortByColumn(propertyName, dataType = "string") {
        let sortedConversations;
        this.activeSort = propertyName;

        if (this.sortDirection[propertyName]) {
          this.sortDirection[propertyName] = false;
          sortedConversations = sortService.sortArrayOfObjects(
            this.conversations,
            propertyName,
            dataType,
            true
          );
        } else {
          this.sortDirection[propertyName] = true;
          sortedConversations = sortService.sortArrayOfObjects(
            this.conversations,
            propertyName,
            dataType
          );
        }

        this.ambassadors = sortedConversations;
      },
      calculateDaysDifference(responseTime) {
        if (responseTime === 0) return "Less than a day";
        else if (responseTime === 1) return `${responseTime} day`;
        else if (responseTime > 1) return `${responseTime} days`;
        else if (!responseTime) return "Pending";
      },
      toggleCloseConversationPopup(conversationID, conversationType) {
        this.conversationToClose = {
          id: conversationID,
          type: conversationType,
        };
        this.closeConversationPopupOpened = true;
      },
      closeConversation() {
        this.$emit("close", this.conversationToClose);
        this.closeConversationPopupOpened = false;
      },
    },
    created() {
      this.conversations = this.rows;
    },
  };
</script>
<style lang="scss" scoped>
  .group {
    &:hover > .active {
      display: none;
    }
    &:hover > .close {
      display: flex;
    }
  }

  .status {
    width: 5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    background-color: $blue-100;
    color: $blue-400;

    &.closed {
      background-color: $pink-100;
      color: $pink-400;
      cursor: default;
    }

    &.active {
      display: flex;
    }

    &.close {
      display: none;
    }
  }

  .prospect-name-icon {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .red-icon {
      fill: var(--v-error-base);
    }
  }
</style>
